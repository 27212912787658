.astronaut-icon {
    width: 14px;
}

.boton-detalle-dtmood {
    border-radius: 4px 4px 4px 4px;
}

.dt-mood-logo {
    width: calc(100% - 30px);
}

.insignia {
    height: 56px;
    width: 56px;
}

.grey-scale {
    filter: grayscale(1);
    transition-property: filter;
    transition-duration: 1s;     
}

.text-blue-dtmood {
    color: $dt_mood_blue;
}

.text-dark-dtmood {
    color: #181B45;
}

.text-gray-dtmood {
    color: var($body-color);
}

.text-white {
    color: var($white);
}

.text-red-dtmood {
    color: $red;
}

@media (max-width: 520px) {
    .dt-mood-logo {
        // width: 60px;
    }
}

@media (max-width: 490px) {
    .btn-dt-mood {
        font-size: 0.5rem;
    }

    .insignia {
        height: 48px;
        width: 48px;
    }
    
}

@media (max-width: 438px) {
    .dt-mood-icon {
        display: none;
    }    
}