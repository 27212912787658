.sweet-confirm-button {
    background-color: #7460ee !important;
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.sweet-text {
    color: #67757C !important;
    font-size: 16px !important;
}

.sweet-text-pitazo {
    color: #F93627 !important;
    font-size: 18px !important;
}

.sweet-title {
    color: #111111 !important;
    font-size: 20px !important;
}