.auth-wrapper {
  min-height: 100vh;
  position: relative;
  .auth-box {
    background: $white;
    padding: 20px;
    box-shadow: $shadow;
    max-width: 400px;
    width: 90%;
    margin: 10% 0;
    .logo {
      text-align: center;
    }
    &.on-sidebar {
      top: 0px;
      right: 0px;
      height: 100%;
      margin: 0px;
      position: absolute;
    }
  }
  #recoverform {
    display: none;
  }
  .auth-sidebar {
    position: fixed;
    height: 100%;
    right: 0px;
    overflow: auto;
    margin: 0px;
    top: 0px;
  }
}

.error {
  color: $danger;
}

@include media-breakpoint-down(sm) {
  .auth-wrapper {
    .auth-sidebar {
      position: relative;
      max-width: 100%;
      width: 100%;
      margin: 40px 0 60px;
    }
    .demo-text {
      margin-top: 30px;
    }
  }
}

.auth-bg {
  background-color: #7460EE;
  width: clac(100% -400px);
}

.auth-bg-dtmood {
  background: transparent linear-gradient(0deg, #685BC7 0%, #2CCCD3 100%) 0% 0% no-repeat padding-box;
  width: clac(100% -400px);
}

.bg-image{
  position: absolute;
  width: 33%;
  left: calc((100% - 300px) / 4 );
}

.bg-image-dtmood{
  position: absolute;
  width: 60%;
  left: 5%;
}

@media (max-width: 1300px) {
  .bg-image-dtmood{
    width: 50%;
    left: 5%;
  }
  }

@media (max-width: 800px) {
  .bg-image{
    width: 25%;
  }
}

@media (max-width: 700px) {
  .bg-image{
    left: calc((100% - 400px) / 5 );
  }
}

@media (max-width: 600px) {
  .bg-image{
    display: none;
  }
}