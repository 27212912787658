// Theme colors
#main-wrapper {
  &[data-layout="vertical"],
  &[data-layout="horizontal"] {
    /*This is for the logo bg*/
    .topbar .top-navbar .navbar-header {
      &[data-logobg="skin1"] {
        background: $dark-top-bar;
      }
      &[data-logobg="skin2"] {
        background: $skin2;
      }
      &[data-logobg="skin3"] {
        background: $skin3;
      }
      &[data-logobg="skin4"] {
        background: $skin4;
      }
      &[data-logobg="skin5"] {
        background: $skin5;
      }
      &[data-logobg="skin6"] {
        background: $skin6;
        .navbar-brand {
          .dark-logo {
            display: inline;
          }
          .light-logo {
            display: none;
          }
        }
        .nav-toggler,
        .topbartoggler {
          color: $body-color !important;
        }
      }
    }
    .topbar .navbar-collapse,
    .topbar {
      &[data-navbarbg="skin1"] {
        background: $dark-top-bar;
      }
      &[data-navbarbg="skin2"] {
        background: $skin2;
      }
      &[data-navbarbg="skin3"] {
        background: $skin3;
      }
      &[data-navbarbg="skin4"] {
        background: $skin4;
      }
      &[data-navbarbg="skin5"] {
        background: $skin5;
      }
      &[data-navbarbg="skin6"] {
        background: $skin6;
      }
    }
    /*This is for the sidebar bg*/
    .left-sidebar {
      &[data-sidebarbg="skin1"],
      &[data-sidebarbg="skin1"] ul {
        background: $dark-side-bar;
        .sidebar-nav {
          ul {
            background: $dark-side-bar;
            // background: black;
            .sidebar-item {
              .sidebar-link {
                opacity: 1;
                color: $sidebar-text-dark;
                i {
                  color: $sidebar-icons-dark;
                }
                &.active {
                  font-weight: 500;
                  color: $gray-800;
                  i {
                    color: $gray-800;
                  }
                }
                &:hover {
                  color: $gray_chronus;
                  i {
                    color: $gray_chronus;
                  }
                  &:after {
                    border-color: $gray_chronus;
                  }
                }
              }
              &.selected > .sidebar-link {
                background: $dark-side-bar;
                border: 1.5px solid $gray_chronus !important;
                color: $gray_chronus;
                border-radius: 4px;
                opacity: 1;
                box-shadow: none !important;
                //font-weight: 500;
                i {
                  color: $gray_chronus;
                }
                &:after {
                  border-color: $white;
                }
                &:hover {
                  color: $gray_chronus;
                  i {
                    color: $gray_chronus;
                  }
                  &:after {
                    border-color: $gray_chronus;
                  }
                }
              }
              .first-level {
                .sidebar-item.selected > .sidebar-link {
                  background: transparent;
                  font-weight: 500;
                  color: $white;
                  i {
                    color: $white;
                  }
                }
              }
            }
            .nav-small-cap {
              color: $sidebar-text-dark;
              opacity: 0.7;
            }
          }
          .has-arrow {
            &::after {
              border-color: $sidebar-text-dark;
            }
          }
        }
      }
      &[data-sidebarbg="skin2"],
      &[data-sidebarbg="skin2"] ul {
        background: $skin2;
        .sidebar-item {
          &.selected > .sidebar-link {
            border: 1.5px solid $white;
            background: $skin2;
            box-shadow: none !important;
          }
          .first-level {
            .sidebar-item.selected .sidebar-link {
              background: transparent;
            }
          }
        }
      }
      &[data-sidebarbg="skin3"],
      &[data-sidebarbg="skin3"] ul {
        background: $skin3;
        .sidebar-item {
          &.selected > .sidebar-link {
            border: 1.5px solid $white;
            background: $skin3;
            box-shadow: none !important;
          }
          .first-level {
            .sidebar-item.selected .sidebar-link {
              background: transparent;
            }
          }
        }
      }
      &[data-sidebarbg="skin4"],
      &[data-sidebarbg="skin4"] ul {
        background: $skin4;
        .sidebar-item {
          &.selected > .sidebar-link {
            border: 1.5px solid $white;
            background: $skin4;
            box-shadow: none !important;
          }
          .first-level {
            .sidebar-item.selected .sidebar-link {
              background: transparent;
            }
          }
        }
      }
      &[data-sidebarbg="skin5"],
      &[data-sidebarbg="skin5"] ul {
        background: $skin5;
        .sidebar-nav {
          ul {
            background: $skin5;
            .sidebar-item {
              .sidebar-link {
                opacity: 1;
                color: $white;
                i {
                  color: $white;
                }
                &.active {
                  font-weight: 500;
                  color: $gray-800;
                  i {
                    color: $gray-800;
                  }
                }
                &:hover {
                  color: $white;
                  i {
                    color: $white;
                  }
                  &:after {
                    border-color: $white;
                  }
                }
              }
              &.selected > .sidebar-link {
                background: $skin5;
                border: 1.5px solid $white !important;
                color: $white;
                border-radius: 4px;
                opacity: 1;
                box-shadow: none !important;
                //font-weight: 500;
                i {
                  color: $white;
                }
                &:after {
                  border-color: $white;
                }
                &:hover {
                  color: $white;
                  i {
                    color: $white;
                  }
                  &:after {
                    border-color: $success;
                  }
                }
              }
              .first-level {
                .sidebar-item.selected > .sidebar-link {
                  background: transparent;
                  font-weight: 500;
                  color: $gray-800;
                  i {
                    color: $gray-800;
                  }
                }
              }
            }
            .nav-small-cap {
              color: $sidebar-text-dark;
              opacity: 0.7;
            }
          }
          .has-arrow {
            &::after {
              border-color: $sidebar-text-dark;
            }
          }
        }
      }
      &[data-sidebarbg="skin6"],
      &[data-sidebarbg="skin6"] ul {
        background: $skin6;
        .sidebar-nav {
          ul {
            background: $skin6;
            .sidebar-item {
              .sidebar-link {
                opacity: 1;
                color: $sidebar-text-dark;
                i {
                  color: $sidebar-icons-dark;
                }
                &.active {
                  font-weight: 500;
                  color: $gray-800;
                  i {
                    color: $gray-800;
                  }
                }
                &:hover {
                  color: $purple_chronus;
                  i {
                    color: $purple_chronus;
                  }
                  &:after {
                    border-color: $purple_chronus;
                  }
                }
              }
              &.selected > .sidebar-link {
                background: $skin6;
                border: 1.5px solid $purple_chronus !important;
                color: $purple_chronus;
                border-radius: 4px;
                opacity: 1;
                box-shadow: none !important;
                //font-weight: 500;
                i {
                  color: $purple_chronus;
                }
                &:after {
                  border-color: $white;
                }
                &:hover {
                  color: $purple_chronus;
                  i {
                    color: $purple_chronus;
                  }
                  &:after {
                    border-color: $success;
                  }
                }
              }
              .first-level {
                .sidebar-item.selected > .sidebar-link {
                  background: transparent;
                  font-weight: 500;
                  color: $gray-800;
                  i {
                    color: $gray-800;
                  }
                }
              }
            }
            .nav-small-cap {
              color: $sidebar-text-dark;
              opacity: 0.7;
            }
          }
          .has-arrow {
            &::after {
              border-color: $sidebar-text-dark;
            }
          }
        }
      }
    }
  }
}
